import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';

import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import SEO from '../../../components/seo';
import { GlobalStyle, ContentWrapper } from '../sassModern.style';
import { Wrapper, Content, Title } from './terms.style';

const Terms = () => (
  <ThemeProvider theme={saasModernTheme}>
    <Fragment>
      <SEO title="Terms & Conditions" />

      <ResetCSS />
      <GlobalStyle />

      <Wrapper>
        <Content>
          <div className="panel-body staticPage" id="terms">
            <Title>Terms of Service</Title>

            <p>
              These Terms of Service ("<strong>Terms</strong>") govern your
              access to and use of the services, including our various websites,
              SMS, APIs, email notifications, applications, buttons, and
              widgets, (the "<strong>Services</strong>" or “
              <strong>MagicTablePlanner</strong>
              ”), and any information, text, graphics, photos or other materials
              uploaded, downloaded or appearing on the Services (collectively
              referred to as "<strong>Content</strong>"). Your access to and use
              of the Services are conditioned on your acceptance of and
              compliance with these Terms. By accessing or using the Services
              you agree to be bound by these Terms.
            </p>

            <h2>1. Basic Terms</h2>

            <p>
              You are responsible for your use of the Services, for any Content
              you post to the Services, and for any consequences thereof. The
              Content you submit, post, or display will be able to be viewed by
              other users of the Services.
            </p>

            <p>
              You may use the Services only if you can form a binding contract
              with MagicTablePlanner and are not a person barred from receiving
              services under the laws of the United States or other applicable
              jurisdiction. If you are accepting these Terms and using the
              Services on behalf of a company, organization, government, or
              other legal entity, you represent and warrant that you are
              authorized to do so. You may use the Services only in compliance
              with these Terms and all applicable local, state, national, and
              international laws, rules and regulations.
            </p>

            <p>
              The Services that MagicTablePlanner provides are always evolving
              and the form and nature of the Services that MagicTablePlanner
              provides may change from time to time without prior notice to you.
              In addition, MagicTablePlanner may stop (permanently or
              temporarily) providing the Services (or any features within the
              Services) to you or to users generally and may not be able to
              provide you with prior notice. We also retain the right to create
              limits on use and storage at our sole discretion at any time
              without prior notice to you.
            </p>

            <h2>2. Privacy</h2>

            <p>
              Any information that you provide to MagicTablePlanner is subject
              to our <a href="/privacy">Privacy Policy</a>, which governs our
              collection and use of your information.
            </p>

            <h2>3. Passwords</h2>

            <p>
              You are responsible for safeguarding the password that you use to
              access the Services and for any activities or actions under your
              password. We encourage you to use "strong" passwords (passwords
              that use a combination of upper and lower case letters, numbers
              and symbols) with your account. MagicTablePlanner cannot and will
              not be liable for any loss or damage arising from your failure to
              comply with the above.
            </p>

            <h2>4. Content on the Services</h2>

            <p>
              All Content posted is the sole responsibility of the person who
              originated such Content. We may not monitor or control the Content
              posted via the Services and, we cannot take responsibility for
              such Content. Any use or reliance on any Content or materials
              posted via the Services or obtained by you through the Services is
              at your own risk.
            </p>

            <p>
              We do not endorse, support, represent or guarantee the
              completeness, truthfulness, accuracy, or reliability of any
              Content or communications posted via the Services or endorse any
              opinions expressed via the Services. You understand that by using
              the Services, you may be exposed to Content that might be
              offensive, harmful, inaccurate or otherwise inappropriate, or in
              some cases, postings that have been mislabeled or are otherwise
              deceptive. Under no circumstances will MagicTablePlanner be liable
              in any way for any Content, including, but not limited to, any
              errors or omissions in any Content, or any loss or damage of any
              kind incurred as a result of the use of any Content posted,
              emailed, transmitted or otherwise made available via the Services
              or broadcast elsewhere.
            </p>

            <h2>6. Your License To Use the Services</h2>

            <p>
              MagicTablePlanner gives you a personal, worldwide, royalty-free,
              non-assignable and non-exclusive license to use the software that
              is provided to you by MagicTablePlanner as part of the Services.
              This license is for the sole purpose of enabling you to use and
              enjoy the benefit of the Services as provided by
              MagicTablePlanner, in the manner permitted by these Terms.
            </p>

            <h2>7. MagicTablePlanner Rights</h2>

            <p>
              All right, title, and interest in and to the Services are and will
              remain the exclusive property of MagicTablePlanner and its
              licensors. The Services are protected by copyright, trademark, and
              other laws of both the United States and foreign countries.
              Nothing in the Terms gives you a right to use the
              MagicTablePlanner name or any of the MagicTablePlanner trademarks,
              logos, domain names, and other distinctive brand features. Any
              feedback, comments, or suggestions you may provide regarding
              MagicTablePlanner, or the Services is entirely voluntary and we
              will be free to use such feedback, comments or suggestions as we
              see fit and without any obligation to you.
            </p>

            <h2>9. Copyright Policy</h2>

            <p>
              All website design, text, graphics, source code and layout are
              copyright MagicTablePlanner unless otherwise stated. You are,
              however, permitted to download or print extracts (such as
              individual table plans).
            </p>

            <h2>10. Ending These Terms</h2>

            <p>
              The Terms will continue to apply until terminated by either you or
              MagicTablePlanner as follows.
            </p>

            <p>
              We may suspend or terminate your accounts or cease providing you
              with all or part of the Services at any time for any reason,
              including, but not limited to, if we reasonably believe: (i) you
              have violated these Terms, (ii) you create risk or possible legal
              exposure for us; or (iii) our provision of the Services to you is
              no longer commercially viable. We will make reasonable efforts to
              notify you by the email address associated with your account or
              the next time you attempt to access your account.
            </p>

            <p>
              In all such cases, the Terms shall terminate, including, without
              limitation, your license to use the Services, except that the
              following sections shall continue to apply: 4, 5, 7, 8, 10, 11,
              and 12.
            </p>

            <p>
              Nothing in this section shall affect MagicTablePlanner’s rights to
              change, limit or stop the provision of the Services without prior
              notice, as provided above in section 1.
            </p>

            <h2>11.Disclaimers and Limitations of Liability</h2>

            <p>
              Please read this section carefully since it limits the liability
              of MagicTablePlanner and its parents, subsidiaries, affiliates,
              related companies, officers, directors, employees, agents,
              representatives, partners, and licensors (collectively, the
              “MagicTablePlanner Entities”). Each of the subsections below only
              applies up to the maximum extent permitted under applicable law.
              Some jurisdictions do not allow the disclaimer of implied
              warranties or the limitation of liability in contracts, and as a
              result the contents of this section may not apply to you. Nothing
              in this section is intended to limit any rights you may have which
              may not be lawfully limited.
            </p>

            <h3>A. The Services are Available "AS-IS"</h3>
            <p>
              Your access to and use of the Services or any Content are at your
              own risk. You understand and agree that the Services are provided
              to you on an "AS IS" and "AS AVAILABLE" basis. Without limiting
              the foregoing, to the maximum extent permitted under applicable
              law, THE MagicTablePlanner ENTITIES DISCLAIM ALL WARRANTIES AND
              CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
            </p>

            <p>
              The MagicTablePlanner Entities make no warranty and disclaim all
              responsibility and liability for: (i) the completeness, accuracy,
              availability, timeliness, security or reliability of the Services
              or any Content; (ii) any harm to your computer system, loss of
              data, or other harm that results from your access to or use of the
              Services or any Content; (iii) the deletion of, or the failure to
              store or to transmit, any Content and other communications
              maintained by the Services; and (iv) whether the Services will
              meet your requirements or be available on an uninterrupted,
              secure, or error-free basis. No advice or information, whether
              oral or written, obtained from the MagicTablePlanner Entities or
              through the Services, will create any warranty not expressly made
              herein.
            </p>

            <h3>B. Links</h3>
            <p>
              The Services may contain links to third-party websites or
              resources. You acknowledge and agree that the MagicTablePlanner
              Entities are not responsible or liable for: (i) the availability
              or accuracy of such websites or resources; or (ii) the content,
              products, or services on or available from such websites or
              resources. Links to such websites or resources do not imply any
              endorsement by the MagicTablePlanner Entities of such websites or
              resources or the content, products, or services available from
              such websites or resources. You acknowledge sole responsibility
              for and assume all risk arising from your use of any such websites
              or resources.
            </p>

            <h3>C. Limitation of Liability</h3>

            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE
              MagicTablePlanner ENTITIES SHALL NOT BE LIABLE FOR ANY INDIRECT,
              INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY
              LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR
              INDIRECTLY, OR ANY LOSS OF DATA, USE, GOOD-WILL, OR OTHER
              INTANGIBLE LOSSES, RESULTING FROM (i) YOUR ACCESS TO OR USE OF OR
              INABILITY TO ACCESS OR USE THE SERVICES; (ii) ANY CONDUCT OR
              CONTENT OF ANY THIRD PARTY ON THE SERVICES, INCLUDING WITHOUT
              LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER
              USERS OR THIRD PARTIES; (iii) ANY CONTENT OBTAINED FROM THE
              SERVICES; OR (iv) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR
              TRANSMISSIONS OR CONTENT.
            </p>

            <p>
              THE LIMITATIONS OF THIS SUBSECTION SHALL APPLY TO ANY THEORY OF
              LIABILITY, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT
              (INCLUDING NEGLIGENCE) OR OTHERWISE, AND WHETHER OR NOT THE
              MagicTablePlanner ENTITIES HAVE BEEN INFORMED OF THE POSSIBILITY
              OF ANY SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND
              TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
            </p>

            <h2>12. General Terms</h2>

            <h3>A. Waiver and Severability</h3>

            <p>
              The failure of MagicTablePlanner to enforce any right or provision
              of these Terms will not be deemed a waiver of such right or
              provision. In the event that any provision of these Terms is held
              to be invalid or unenforceable, then that provision will be
              limited or eliminated to the minimum extent necessary, and the
              remaining provisions of these Terms will remain in full force and
              effect.
            </p>

            <h3>B. Controlling Law and Jurisdiction</h3>

            <p>
              These Terms and any action related thereto will be governed by the
              laws of the State of California without regard to or application
              of its conflict of law provisions or your state or country of
              residence. All claims, legal proceedings or litigation arising in
              connection with the Services will be brought solely in courts
              located in Angers, France, and you consent to the jurisdiction of
              and venue in such courts and waive any objection as to
              inconvenient forum.
            </p>

            <p>
              If you are a federal, state, or local government entity in the
              United States using the Services in your official capacity and
              legally unable to accept the controlling law, jurisdiction or
              venue clauses above, then those clauses do not apply to you. For
              such U.S. federal government entities, these Terms and any action
              related thereto will be governed by the laws of the United States
              of America (without reference to conflict of laws) and, in the
              absence of federal law and to the extent permitted under federal
              law, the laws of the State of California (excluding choice of
              law).
            </p>

            <h3>C. Entire Agreement</h3>

            <p>
              These Terms, and our <a href="/privacy">Privacy Policy</a> are the
              entire and exclusive agreement between MagicTablePlanner and you
              regarding the Services (excluding any services for which you have
              a separate agreement with MagicTablePlanner that is explicitly in
              addition or in place of these Terms), and these Terms supersede
              and replace any prior agreements between MagicTablePlanner and you
              regarding the Services. Other than members of the group of
              companies of which MagicTablePlanner, Inc. is the parent, no other
              person or company will be third party beneficiaries to the Terms.
            </p>

            <p>
              We may revise these Terms from time to time, the most current
              version will always be at{' '}
              <a href="/terms">MagicTablePlanner.com/terms</a>. If the revision,
              in our sole discretion, is material we will notify you via e-mail
              associated with your account. By continuing to access or use the
              Services after those revisions become effective, you agree to be
              bound by the revised Terms.
            </p>

            <p>
              These Services are operated and provided by MagicTablePlanner
              Inc., 101 rue de Frémur, 49000 Angers, France. If you have any
              questions about these Terms, please send us an email at{' '}
              <a href="mailto:support@magictableplanner.com">
                support@magictableplanner.com
              </a>
              .
            </p>

            <h2>13. Legal information</h2>

            <p />
            <ul>
              <li>The owner and founder is : Alexandre Xypas</li>
              <li>
                Postal address of the owner : 101 rue de Frémur, 49 000 ANGERS,
                France
              </li>
              <li>To contact us : admin@magictableplanner.com</li>
              <li>The Webmaster is : Alexandre Xypas</li>
              <li>
                The hosting service is: OVH SAS 2, rue Kellermann, 59100
                ROUBAIX, FRANCE
              </li>
            </ul>
            <p />
          </div>
        </Content>
      </Wrapper>
    </Fragment>
  </ThemeProvider>
);

export default Terms;
